import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {createUnit, getUnit, patchUnit} from 'redux/action/unitsAction'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'

const UnitDetail = (props) => {
  const {classes, patchUnit, createUnit, getUnit, unit, unitLoading} = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createUnit(preparedValues)
        .then((res) => {
          redirectTo('/unit')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchUnit(id, preparedValues)
        .then((res) => {
          redirectTo('/unit')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...unit,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getUnit(id)
      }
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="description"
                      label={<Trans>Description</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

UnitDetail.propTypes = {
  classes: PropTypes.object,
  patchUnit: PropTypes.func,
  getUnit: PropTypes.func,
  unit: PropTypes.object,
  unitLoading: PropTypes.bool,
  createUnit: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchUnit,
      createUnit,
      getUnit,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      unit: store.unit.unit,
      unitLoading: store.unit.unitLoading,
    }
  }, mapDispatchToProps)
)(UnitDetail)
