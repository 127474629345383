import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import SelectInput from 'component/field/SelectInput'
import {useParams} from 'react-router-dom'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CheckBoxInput from 'component/field/CheckBoxInput'
import PrimaryButton from 'component/material/PrimaryButton'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {createCustomer, getCustomer, patchCustomer} from 'redux/action/customersAction'
import {getProviderList} from 'redux/action/providersAction'
import Box from '@material-ui/core/Box'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'

const CustomerDetail = (props) => {
  const {
    classes,
    customer,
    customerLoading,
    getCustomer,
    getProviderList,
    patchCustomer,
    providerList,
    providerListLoading,
    createCustomer,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      enabled: !!values.enabled,
      invoice_bulk: !!values.invoice_bulk,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createCustomer(preparedValues)
        .then((res) => {
          redirectTo('/customer')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchCustomer(id, preparedValues)
        .then((res) => {
          redirectTo('/customer')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    if (id !== 'new') {
      return customer
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getCustomer(id)
      }
      getProviderList(1000, 0)
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="name"
                      label={<Trans>Name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="ic"
                      label={<Trans>IC</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="dic"
                      label={<Trans>DIC</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="street"
                      label={<Trans>Street</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="building_no"
                      label={<Trans>Building No.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="city"
                      label={<Trans>City</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="postal_zone"
                      label={<Trans>Postal zone</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="account_no"
                      label={<Trans>Account no.:</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={1}
                />

                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={5}
                >
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>System information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="provider_id"
                      label={<Trans>Provider</Trans>}
                      component={SelectInput}
                      options={providerList}
                      loading={providerListLoading}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="cfs"
                      label={<Trans>Customer ID</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="ean"
                      label={<Trans>EAN</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="eos_id"
                      label={<Trans>EOS Id</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="invoice_due"
                      label={<Trans>Invoice due</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="invoice_bulk"
                      label={<Trans>Invoice bulk</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>

              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

CustomerDetail.propTypes = {
  classes: PropTypes.object,
  customer: PropTypes.object,
  customerLoading: PropTypes.bool,
  getCustomer: PropTypes.func,
  getProviderList: PropTypes.func,
  patchCustomer: PropTypes.func,
  providerList: PropTypes.array,
  providerListLoading: PropTypes.bool,
  createCustomer: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomer,
      getProviderList,
      patchCustomer,
      createCustomer,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      customer: store.customer.customer,
      customerLoading: store.customer.customerLoading,
      providerList: store.provider.providerList,
      providerListLoading: store.provider.providerListLoading,
    }
  }, mapDispatchToProps)
)(CustomerDetail)
