import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import SelectInput from 'component/field/SelectInput'
import {useParams} from 'react-router-dom'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CheckBoxInput from 'component/field/CheckBoxInput'
import PrimaryButton from 'component/material/PrimaryButton'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getCustomerList} from 'redux/action/customersAction'
import {createFleet, getFleet, patchFleet} from 'redux/action/fleetsAction'
import {getBranchList} from 'redux/action/branchesAction'
import {getAccountList} from 'redux/action/accountsAction'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import Box from '@material-ui/core/Box'

const FleetDetail = (props) => {
  const {
    classes,
    fleet,
    fleetLoading,
    getFleet,
    getCustomerList,
    patchFleet,
    createFleet,
    customerList,
    customerListLoading,
    getBranchList,
    getAccountList,
    accountList,
    accountListLoading,
    branchList,
    branchListLoading,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      bonus: values.bonus ? Number(values.bonus) : 0,
      enabled: !!values.enabled,
      case_with_painting_allowed: !!values.case_with_painting_allowed,
      default_fleet: !!values.default_fleet,
      do_not_show_on_map: !!values.do_not_show_on_map,
      use_discount_bit: !!values.use_discount_bit,
      use_limit_price: !!values.use_limit_price,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createFleet(preparedValues)
        .then((res) => {
          redirectTo('/fleet')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchFleet(id, preparedValues)
        .then((res) => {
          redirectTo('/fleet')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...fleet,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getFleet(id)
      }
      getCustomerList(1000, 0, {orderBy: 'name', orderDirection: 'ASC'})
      getBranchList()
      getAccountList()
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="customer_id"
                      label={<Trans>Customer</Trans>}
                      component={SelectInput}
                      options={customerList}
                      loading={customerListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="branch_id"
                      label={<Trans>Branch</Trans>}
                      component={SelectInput}
                      options={branchList?.map((branch) => ({
                        name: branch.description_code
                          ? branch.description_code
                          : branch.description,
                        value: branch.id,
                      }))}
                      loading={branchListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="account_id"
                      label={<Trans>Account</Trans>}
                      component={SelectInput}
                      options={accountList?.map((acc) => ({
                        name: acc.description_code ? acc.description_code : acc.description,
                        value: acc.id,
                      }))}
                      loading={accountListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="bonus"
                      label={<Trans>Bonus</Trans>}
                      component={TextInput}
                      type={'number'}
                      inputJustifyRight={true}
                      inputProps={{min: 0, step: 1}}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="code"
                      label={<Trans>Code</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="substitution_method"
                      label={<Trans>Substitution method</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer item xs={1} />

                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={5}
                >
                  <BoxFullWidth p={2} className={classes.formTitle}></BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="case_with_painting_allowed"
                      label={<Trans>Case with painting allowed</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="default_fleet"
                      label={<Trans>Default fleet</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="use_limit_price"
                      label={<Trans>Use limit price</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="use_discount_bit"
                      label={<Trans>Use discount bit</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="do_not_show_on_map"
                      label={<Trans>Do not show on map</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

FleetDetail.propTypes = {
  classes: PropTypes.object,
  fleet: PropTypes.object,
  fleetLoading: PropTypes.bool,
  getFleet: PropTypes.func,
  getCustomerList: PropTypes.func,
  customerList: PropTypes.array,
  customerListLoading: PropTypes.bool,
  getBranchList: PropTypes.func,
  getAccountList: PropTypes.func,
  accountList: PropTypes.array,
  accountListLoading: PropTypes.bool,
  branchList: PropTypes.array,
  branchListLoading: PropTypes.bool,
  patchFleet: PropTypes.func,
  createFleet: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleet,
      getCustomerList,
      getBranchList,
      getAccountList,
      patchFleet,
      createFleet,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      fleet: store.fleet.fleet,
      fleetLoading: store.fleet.fleetLoading,
      customerList: store.customer.customerList,
      customerListLoading: store.customer.customerListLoading,
      accountList: store.account.accountList,
      accountListLoading: store.account.accountListLoading,
      branchList: store.branch.branchList,
      branchListLoading: store.branch.branchListLoading,
    }
  }, mapDispatchToProps)
)(FleetDetail)
