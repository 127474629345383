import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import {
  CREATE_USERS_FAIL,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_SUCCESS,
  GET_USER_FAIL,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USERS_FAIL,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'
import React from 'react'

export const getUserList = (limit = 1000, offset = 0, meta = {}, filters = {}) => {
  return (dispatch) => {
    dispatch({type: GET_USER_LIST_REQUEST})
    return httpClient
      .get(endpoints.user, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        username: filters?.username ? filters.username : null,
        email: filters?.email ? filters.email : null,
      })
      .then((res) => {
        dispatch({type: GET_USER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        dispatch({type: GET_USER_LIST_FAIL})
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getUser = (id) => {
  return (dispatch) => {
    dispatch({type: GET_USER_REQUEST})
    return httpClient
      .get(endpoints.user + '/' + id)
      .then((res) => {
        dispatch({type: GET_USER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_USER_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createUser = (data) => {
  return (dispatch) => {
    dispatch({type: CREATE_USERS_REQUEST})
    return httpClient
      .post(endpoints.user, data)
      .then((res) => {
        fireSuccessToast(<Trans>New user created.</Trans>)
        dispatch({type: CREATE_USERS_SUCCESS})
        return res.data
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        dispatch({type: CREATE_USERS_FAIL})
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch({type: DELETE_USERS_REQUEST})
    return httpClient
      .del(`${endpoints.user}/${id}`)
      .then((res) => {
        dispatch({type: DELETE_USERS_SUCCESS})
        return Promise.resolve()
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        dispatch({type: DELETE_USERS_FAIL})
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchUser = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_USERS_REQUEST})
    return httpClient
      .patch(`${endpoints.user}/${id}`, data)
      .then((res) => {
        dispatch({type: UPDATE_USERS_SUCCESS})
        return Promise.resolve()
      })
      .catch((err) => {
        dispatch({type: UPDATE_USERS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const editPassword = (id, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.user}/${id}/reset-password`, data)
      .then((res) => {
        return true
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getRoles = () => {
  return (dispatch) => {
    dispatch({type: GET_ROLE_LIST_REQUEST})
    return httpClient
      .get(endpoints.role)
      .then((res) => {
        dispatch({type: GET_ROLE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        dispatch({type: GET_USER_LIST_FAIL})
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
