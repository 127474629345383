import DatePicker from 'react-datepicker'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {bindActionCreators, compose} from 'redux'
import {getSupUnavailabilities, putSupUnavailabilities} from 'redux/action/suppliersAction'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import {DB_DATE_FORMAT} from 'helper/constants'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import CustomTooltip from 'component/material/CustomTooltip'
import {enGB} from 'date-fns/locale'
import {CircularProgress} from '@material-ui/core'

const AvailabilityCalendar = (props) => {
  const {
    getSupUnavailabilities,
    putSupUnavailabilities,
    supplierUnavaLoading,
    supplierUnava,
    onClose,
    id,
  } = props

  const [unavDates, setUnavDates] = useState([])

  const handleSelectDate = (date) => {
    const unavailable = unavDates?.includes(date)
    if (unavailable) {
      setUnavDates((prevState) => [...prevState.filter((d) => d !== date)])
    } else {
      setUnavDates((prevState) => [...prevState, date])
    }
  }

  const renderDayContents = (day, date) => {
    const formattedDate = moment(date).format(DB_DATE_FORMAT)
    const unavailable = unavDates?.includes(formattedDate)
    return (
      <span
        style={{
          backgroundColor: unavailable ? '#ff000020' : 'initial',
          borderRadius: 4,
          color: unavailable ? 'red' : 'inherit',
          display: 'block',
          fontSize: 15,
        }}
      >
        {day}
      </span>
    )
  }

  const handleSaveDates = () => {
    if (id) {
      const data = unavDates?.map((d) => ({date: d}))
      putSupUnavailabilities(id, {unavailabilities: data}).then(() => {
        onClose && onClose()
      })
    }
  }

  const loadingBoxStyle = {
    width: 'calc(100% + 8px)',
    marginLeft: '-4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1,
    backdropFilter: 'blur(2px)',
  }

  useEffect(() => {
    if (!supplierUnavaLoading && Array.isArray(unavDates)) {
      setUnavDates(supplierUnava.map((d) => d.date))
    }
  }, [supplierUnava, supplierUnavaLoading])

  useEffect(() => {
    if (id) {
      getSupUnavailabilities(id)
    }
    return () => {
      setUnavDates([])
    }
  }, [])

  return (
    <GridContainer>
      <GridItem xs={12} style={{position: 'relative'}}>
        {supplierUnavaLoading && (
          <Box style={loadingBoxStyle}>
            <CircularProgress />
          </Box>
        )}
        <DatePicker
          inline
          locale={enGB}
          calendarStartDay={1}
          shouldCloseOnSelect={false}
          minDate={new Date()}
          renderDayContents={renderDayContents}
          onSelect={(date) => handleSelectDate(moment(date).format(DB_DATE_FORMAT))}
          monthsShown={2}
          dayClassName={() => 'calendar-day-class'}
        />
        <Box style={{display: 'flex', paddingTop: 14, justifyContent: 'flex-end', gap: 16}}>
          <CustomTooltip title={<Trans>Reset to last saved value</Trans>}>
            <PrimaryButton
              onClick={() => setUnavDates(supplierUnava?.map((d) => d.date))}
              variant={'outlined'}
              size={'small'}
              fullWidth={false}
              text={<Trans>Reset</Trans>}
            />
          </CustomTooltip>
          <PrimaryButton
            onClick={handleSaveDates}
            variant={'contained'}
            size={'small'}
            fullWidth={false}
            text={<Trans>Save</Trans>}
          />
        </Box>
      </GridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSupUnavailabilities,
      putSupUnavailabilities,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      supplierUnavaLoading: store.supplier.supplierUnavaLoading,
      supplierUnava: store.supplier.supplierUnava,
    }
  }, mapDispatchToProps)
)(AvailabilityCalendar)
