import React, {useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {ReactComponent as Map} from 'style/asset/map.svg'
import {isLoggedUser, redirectTo} from 'helper/functions'
import {getFleetList} from 'redux/action/fleetsAction'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import CustomTooltip from 'component/material/CustomTooltip'
import TextInput from 'component/field/TextInput'

let searchInterval = null

const FleetsPage = (props) => {
  const {classes, fleetList, fleetListLoading, fleetListMeta, getFleetList, rowsPerPage} = props

  const [tableSort, setTableSort] = useState({orderBy: 'customer', orderDirection: 'ASC'})
  const [page, setPage] = useState(0)
  const [searchValue, setSearchValue] = useState(null)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleTableSort = (sort) => {
    setTableSort(sort)
  }

  const openDetail = (row) => (e) => {
    redirectTo('/fleet/' + row.id)
  }

  const handleOpenMap = (row) => (e) => {
    window.open(`${window.location.href}/${row.id}/map`, '_blank').focus()
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'customer',
      sortKey: 'customer',
      label: <Trans>Customer</Trans>,
    },
    {
      name: 'code',
      sortKey: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: 'default_fleet',
      sortKey: 'default_fleet',
      label: <Trans>Default</Trans>,
      render: (val) => (val ? <Trans>default</Trans> : ''),
    },
    {
      name: 'enabled',
      sortKey: 'enabled',
      label: <Trans>Enabled</Trans>,
      render: (val) => (val ? '' : <Trans>disabled</Trans>),
    },

    {
      name: 'case_with_painting_allowed',
      sortKey: 'case_with_painting_allowed',
      label: <Trans>Case with painting allowed</Trans>,
      render: (val) => (val ? '' : <Trans>disallowed</Trans>),
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '80px',
      render: (val, row) => (
        <GridContainer direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <GridItem xs={false}>
            <CustomTooltip title={<Trans>Open fleet map</Trans>}>
              <div className={classes.editIcon} onClick={handleOpenMap(row)}>
                <Map />
              </div>
            </CustomTooltip>
          </GridItem>
          <GridItem xs={false}>
            <CustomTooltip title={<Trans>Edit fleet</Trans>}>
              <div className={classes.editIcon} onClick={openDetail(row)}>
                <Edit />
              </div>
            </CustomTooltip>
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/fleet/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      setPage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getFleetList(rowsPerPage, page * rowsPerPage, tableSort, searchValue)
    } else {
      redirectTo('/')
    }
    return () => {
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={fleetListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New fleet</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>Add</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={fleetList}
          columns={columns}
          loading={fleetListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          count={fleetListMeta.total_count}
          page={page}
          meta={fleetListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

FleetsPage.propTypes = {
  classes: PropTypes.object,
  fleetList: PropTypes.array,
  fleetListLoading: PropTypes.bool,
  fleetListMeta: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getFleetList: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleetList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      fleetList: store.fleet.fleetList,
      fleetListMeta: store.fleet.fleetListMeta,
      fleetListLoading: store.fleet.fleetListLoading,
    }
  }, mapDispatchToProps)
)(FleetsPage)
