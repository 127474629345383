import React, {useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import {connect} from 'react-redux'
import {invoiceExportByType} from 'redux/action/cfsAction'
import cx from 'classnames'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import {Accordion, AccordionDetails, CircularProgress} from '@material-ui/core'
import {useLocation} from 'react-router-dom'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {EXPORT_TYPE} from 'helper/constants'
import {fireSuccessToast} from 'helper/functions'

const ExportInvoiceByType = (props) => {
  const {classes, invoiceExportByType} = props

  const [exportInProgress, setExportInProgress] = useState(false)
  const [type, setType] = useState('')

  let location = useLocation()

  const handleExport = () => {
    setExportInProgress(true)
    return invoiceExportByType(type)
      .then(() => {
        if (type === 'INCOMING') {
          fireSuccessToast(<Trans>Export of incoming invoices has started</Trans>)
        } else {
          fireSuccessToast(<Trans>Export of outgoing invoices has started</Trans>)
        }
      })
      .finally(() => setExportInProgress(false))
  }

  useEffect(() => {
    setType(location.pathname.includes('incoming') ? 'INCOMING' : 'OUTGOING')
    return () => {
      setExportInProgress(false)
    }
  }, [location])

  return (
    <Accordion
      className={cx(classes.tableFilters, classes.tabsCursorDefault)}
      elevation={0}
      expanded={true}
      defaultExpanded={true}
    >
      <AccordionSummary expandIcon={null}>
        <BoxFullWidth className={classes.formTitle}>
          {EXPORT_TYPE.find((t) => t.value === type)?.label} <Trans>export</Trans>
        </BoxFullWidth>
      </AccordionSummary>
      <AccordionDetails>
        <PrimaryButton
          fullWidth={false}
          textPadding={0}
          text={
            exportInProgress ? (
              <GridContainer direction={'row'} spacing={2} container alignItems={'center'}>
                <GridItem xs={false} className={classes.flexAlignCenter}>
                  <CircularProgress size={14} />
                </GridItem>
                <GridItem xs={true}>
                  <Trans>Export in progress</Trans>
                </GridItem>
              </GridContainer>
            ) : (
              <Trans>Export</Trans>
            )
          }
          disabled={exportInProgress}
          onClick={handleExport}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      invoiceExportByType,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(ExportInvoiceByType)
