import React, {useEffect, useState} from 'react'
import {isLoggedUser, redirectTo} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getCfsList, getInvoiceStatuses} from 'redux/action/cfsAction'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Table from 'component/material/table/Table'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import {getCustomerList} from 'redux/action/customersAction'
import {getSupplierList} from 'redux/action/suppliersAction'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import MultiSelectInput from 'component/field/MultiSelectInput'
import moment from 'moment'
import {DATE_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import endpoints from 'helper/endpoints'
import httpClient from 'helper/httpClient'
import AsyncInput from 'component/field/AsyncInput'

let intervalId = null
let searchInterval = null

const CFSPage = (props) => {
  const {
    classes,
    rowsPerPage,
    getCfsList,
    cfsList,
    cfsListLoading,
    cfsListMeta,
    getInvoiceStatuses,
    invoiceStatusListLoading,
    invoiceStatusList,
    customerListLoading,
    supplierListLoading,
  } = props

  const [tableSort, setTableSort] = useState({orderBy: 'date_issue', orderDirection: 'DESC'})
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({})
  const [filtersTemp, setFiltersTemp] = useStateWithCallbackLazy({})
  const [searchValue, setSearchValue] = useState(null)
  const [filtersExpanded, setFiltersExpanded] = useState(true)

  const handleChangeExpand = (expanded) => {
    setFiltersExpanded(expanded)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleTableSort = (sort) => {
    setTableSort(sort)
  }

  const openDetail = (row) => () => {
    redirectTo('/cfs/' + row.id)
  }

  const columns = [
    {
      name: 'supplier_elit_no',
      sortKey: 'supplier_elit_no',
      label: <Trans>Supplier Elit No.</Trans>,
    },
    {
      name: 'customer_name',
      sortKey: 'customer_name',
      label: <Trans>Customer</Trans>,
    },
    {
      name: 'customer_cfs',
      sortKey: 'customer_cfs',
      label: <Trans>Customer CFS only</Trans>,
    },
    {
      name: 'tax_document_no',
      sortKey: 'tax_document_no',
      align: 'right',
      label: <Trans>Invoice no.</Trans>,
    },
    {
      name: 'version',
      sortKey: 'version',
      align: 'right',
      label: <Trans>Version</Trans>,
    },
    {
      name: 'order_no',
      sortKey: 'order_no',
      align: 'right',
      label: <Trans>Order No.</Trans>,
    },
    {
      name: 'rz',
      sortKey: 'rz',
      label: <Trans>RZ</Trans>,
    },
    {
      name: 'date_issue',
      sortKey: 'date_issue',
      align: 'right',
      label: <Trans>Date issue</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const onFilterChange = (e) => {
    const value = e.target?.value
    const name = e.target?.name

    setFiltersTemp(
      (prevState) => ({...prevState, [name]: value}),
      (value) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
          setPage(0)
          setFilters(value)
        }, 500)
      }
    )
  }

  const onAsyncPaginationChange = (name, value) => {
    setFiltersTemp(
      (prevState) => ({...prevState, [name]: value}),
      (value) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
          setPage(0)
          setFilters(value)
        }, 500)
      }
    )
  }

  const handleSubmit = () => {
    // empty submit method, there is no need for form submit, filtering is on change
    // but still it is easier to implement fields with in the form
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      setPage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  const prepareOptionsForMultiSelect = (optionArray, valueKey = 'id', nameKey = 'name') => {
    if (Array.isArray(optionArray)) {
      const options = {}
      optionArray.forEach((item, index) => {
        // if you provide array of objects take id as option value
        const optionIndex = item?.[valueKey] ? item[valueKey] : index
        const optionName = item?.[nameKey] ? item[nameKey] : item

        options[optionIndex] = optionName
      })
      return options
    } else {
      return {}
    }
  }

  const dateEnterEventHandler = (formProps) => (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value
      const name = e.target.name

      if (value) {
        let date = value?.split(' ')?.[0]

        const dateSeparator = date.includes('.')
          ? '.'
          : date.includes('/')
          ? '/'
          : date.includes('-')
          ? '-'
          : null

        if (date?.length > 2 && date?.split(dateSeparator)?.length > 2) {
          let newValueSplit = date?.split(dateSeparator)
          date = newValueSplit
            .map((val, index) => {
              if (newValueSplit?.length - index > 1) {
                if (val?.length === 1) {
                  return `0${val}`
                }
                // last number - year
              } else if (newValueSplit?.length - 1 === index) {
                let year = val
                while (year?.length === 2) {
                  year = moment()?.year().toString()?.slice(0, 2) + year
                }
                while (year?.length < 4) {
                  year += '0'
                }
                return year
              }
              return val
            })
            .join('.')
        }

        const dateTime = `${date ? date : ''}`

        formProps.form.change(name, moment(dateTime, DATE_FORMAT).format(DATE_FORMAT))
        setFiltersTemp(
          (prevState) => ({
            ...prevState,
            [name]: moment(dateTime, DATE_FORMAT).format(DB_DATE_FORMAT),
          }),
          (value) => {
            clearTimeout(intervalId)
            intervalId = setTimeout(() => {
              setPage(0)
              setFilters(value)
            }, 500)
          }
        )
      } else {
        formProps.form.change(name, moment().format(DATE_FORMAT))
        setFiltersTemp(
          (prevState) => ({...prevState, [name]: moment().format(DB_DATE_FORMAT)}),
          (value) => {
            clearTimeout(intervalId)
            intervalId = setTimeout(() => {
              setPage(0)
              setFilters(value)
            }, 500)
          }
        )
      }

      e.preventDefault()
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getCfsList(rowsPerPage, page * rowsPerPage, tableSort, filters, searchValue)
    }
    return () => {
      clearTimeout(intervalId)
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, filters, searchValue])

  useEffect(() => {
    if (isLoggedUser()) {
      getInvoiceStatuses(100, 0, {orderBy: 'description', orderDirection: 'ASC'}).then((res) => {
        setFilters({status: res.objects.map((obj) => obj.id)})
      })
    } else {
      redirectTo('/')
    }
  }, [])

  const loadCustomerOptions = async (search, loadedOptions) => {
    let data = [...loadedOptions]
    let hasMore = false
    await httpClient
      .get(endpoints.customers, {
        limit: 50,
        offset: loadedOptions.length,
        order_by: 'name:ASC',
      })
      .then((res) => {
        data.push(...res?.data?.objects.map((r) => ({value: r.id, label: r.name})))
        hasMore = res?.data?.meta?.total_count > loadedOptions.length
      })
    return {
      options: data,
      hasMore: hasMore,
    }
  }

  const loadSupplierOptions = async (search, loadedOptions) => {
    let data = [...loadedOptions]
    let hasMore = false
    await httpClient
      .get(endpoints.suppliers, {
        limit: 50,
        offset: loadedOptions.length,
        order_by: 'name:ASC',
      })
      .then((res) => {
        data.push(
          ...res?.data?.objects.map((r) => ({
            value: r.id,
            label: `${r.name ? r.name : ''} - ${r.branch ? r.branch : ''}`,
          }))
        )
        hasMore = res?.data?.meta?.total_count > loadedOptions.length
      })
    return {
      options: data,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <Accordion
        className={classes.tableFilters}
        elevation={0}
        expanded={filtersExpanded}
        onChange={(event, expanded) => handleChangeExpand(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <BoxFullWidth className={classes.formTitle}>
            <Trans>Filters</Trans>
          </BoxFullWidth>
        </AccordionSummary>
        <AccordionDetails>
          <Form onSubmit={handleSubmit}>
            {(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <GridContainer
                    spacing={4}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <GridItem container xs={12} sm={6} md={3} lg={2}>
                      <Field
                        disabled={formProps.submitting}
                        name="tax_document_no"
                        label={<Trans>Invoice no.</Trans>}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        filters={true}
                      />
                    </GridItem>

                    <GridItem container xs={12} sm={6} md={3} lg={2}>
                      <AsyncInput
                        label={<Trans>Customer</Trans>}
                        filters={true}
                        loadOptions={loadCustomerOptions}
                        onChange={onAsyncPaginationChange}
                        name={'customer_id'}
                        disabled={formProps.submitting}
                        loading={customerListLoading}
                      />
                    </GridItem>

                    <GridItem contaiser xs={12} sm={6} md={3} lg={2}>
                      <AsyncInput
                        label={<Trans>Supplier</Trans>}
                        filters={true}
                        loadOptions={loadSupplierOptions}
                        onChange={onAsyncPaginationChange}
                        name={'supplier_id'}
                        disabled={formProps.submitting}
                        loading={supplierListLoading}
                      />
                    </GridItem>

                    <GridItem container xs={12} sm={6} md={3} lg={2}>
                      <Field
                        disabled={formProps.submitting}
                        name="rz"
                        label={<Trans>RZ</Trans>}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6} md={3} lg={2}>
                      <Field
                        name="date_from"
                        label={<Trans>Date from</Trans>}
                        disabled={formProps.submitting}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6} md={3} lg={2}>
                      <Field
                        name="date_to"
                        label={<Trans>Date to</Trans>}
                        disabled={formProps.submitting}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={12} md={9} lg={6}>
                      <Field
                        disabled={formProps.submitting}
                        name="status"
                        label={<Trans>Status</Trans>}
                        component={MultiSelectInput}
                        options={
                          invoiceStatusList &&
                          prepareOptionsForMultiSelect(invoiceStatusList, 'id', 'description')
                        }
                        loading={invoiceStatusListLoading}
                        showHelperText={false}
                        onChange={onFilterChange}
                        defaultValue={filters?.status}
                        filters={true}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              )
            }}
          </Form>
        </AccordionDetails>
      </Accordion>

      <BoxFullWidth>
        <div className={classes.listPageControlContainer}>
          <div className={classes.searchContainer}>
            <div className={classes.searchLabel}>
              <Trans>Search:</Trans>
            </div>
            <TextInput
              onChange={handleSearchChange}
              loading={cfsListLoading}
              className={classes.searchInput}
              showHelperText={false}
            />
          </div>
        </div>
        <div className={classes.overFlowAuto}>
          <Table
            data={cfsList}
            columns={columns}
            loading={cfsListLoading}
            onTableColumnSort={handleTableSort}
            tableSort={tableSort}
            count={cfsListMeta.total_count || cfsListMeta.count}
            page={page}
            meta={cfsListMeta}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>
    </>
  )
}

CFSPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getCfsList: PropTypes.func,
  cfsList: PropTypes.array,
  cfsListLoading: PropTypes.bool,
  cfsListMeta: PropTypes.object,
  getCustomerList: PropTypes.func,
  customerList: PropTypes.array,
  customerListLoading: PropTypes.bool,
  getSupplierList: PropTypes.func,
  supplierList: PropTypes.array,
  supplierListLoading: PropTypes.bool,
  getInvoiceStatuses: PropTypes.func,
  invoiceStatusListLoading: PropTypes.bool,
  invoiceStatusList: PropTypes.array,
  invoiceStatusListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCfsList,
      getCustomerList,
      getSupplierList,
      getInvoiceStatuses,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      cfsList: store.cfs.cfsList,
      cfsListLoading: store.cfs.cfsListLoading,
      cfsListMeta: store.cfs.cfsListMeta,
      invoiceStatusList: store.cfs.invoiceStatusList,
      invoiceStatusListLoading: store.cfs.invoiceStatusListLoading,
      invoiceStatusListMeta: store.cfs.invoiceStatusListMeta,
      customerList: store.customer.customerList,
      customerListLoading: store.customer.customerListLoading,
      supplierList: store.supplier.supplierList,
      supplierListLoading: store.supplier.supplierListLoading,
    }
  }, mapDispatchToProps)
)(CFSPage)
