import {
  GET_REGION_FAIL,
  GET_REGION_LIST_FAIL,
  GET_REGION_LIST_REQUEST,
  GET_REGION_LIST_SUCCESS,
  GET_REGION_REQUEST,
  GET_REGION_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getRegionList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_REGION_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.regions + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_REGION_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_REGION_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getRegion = (id) => {
  return (dispatch) => {
    dispatch({type: GET_REGION_REQUEST})
    return httpClient
      .get(endpoints.regions + '/' + id)
      .then((res) => {
        dispatch({type: GET_REGION_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_REGION_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createRegion = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.regions, data)
      .then((res) => {
        fireSuccessToast(<Trans>New region created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteRegion = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.regions + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Region deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchRegion = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.regions + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Region updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
