import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getFleet, getFleetMap} from 'redux/action/fleetsAction'
import {useParams} from 'react-router-dom'
import detailPageStyle from 'component/detailPageStyle'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {getCustomerList} from 'redux/action/customersAction'
import {Trans} from '@lingui/macro'
import Loader from 'component/material/Loader'
import {isLoggedUser, redirectTo} from 'helper/functions'

const FleetMap = (props) => {
  const {
    classes,
    getFleetMap,
    fleetMap,
    fleetMapLoading,
    customerList,
    fleet,
    getFleet,
    getCustomerList,
  } = props

  let {id} = useParams()

  useEffect(() => {
    if (isLoggedUser()) {
      getFleetMap(id)
      getFleet(id)
      getCustomerList()
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <GridContainer>
        <GridItem container xs={5} alignItems={'flex-start'} alignContent={'flex-start'}>
          <GridItem container xs={12} className={classes.fleetDetail}>
            <GridItem xs={5} className={classes.fleetDetailLabel}>
              <Trans>Fleet ID</Trans>
            </GridItem>
            <GridItem xs={7} className={classes.fleetDetailText}>
              {fleet?.id}
            </GridItem>
          </GridItem>

          <GridItem container xs={12} className={classes.fleetDetail}>
            <GridItem xs={5} className={classes.fleetDetailLabel}>
              <Trans>Fleet code</Trans>
            </GridItem>
            <GridItem xs={7} className={classes.fleetDetailText}>
              {fleet?.code}
            </GridItem>
          </GridItem>

          <GridItem container xs={12} className={classes.fleetDetail}>
            <GridItem xs={5} className={classes.fleetDetailLabel}>
              <Trans>Customer name</Trans>
            </GridItem>
            <GridItem xs={7} className={classes.fleetDetailText}>
              {customerList?.filter((customer) => customer.id === fleet?.customer_id)?.[0]?.name}
            </GridItem>
          </GridItem>

          <GridItem container xs={12} className={classes.fleetDetail}>
            <GridItem xs={5} className={classes.fleetDetailLabel}>
              <Trans>Customer CFS</Trans>
            </GridItem>
            <GridItem xs={7} className={classes.fleetDetailText}>
              {fleet?.customer_cfs}
            </GridItem>
          </GridItem>

          <GridItem container xs={12} className={classes.fleetDetail}>
            <GridItem xs={5} className={classes.fleetDetailLabel}>
              <Trans>Customer IC</Trans>
            </GridItem>
            <GridItem xs={7} className={classes.fleetDetailText}>
              {customerList?.filter((customer) => customer.id === fleet?.customer_id)?.[0]?.ic}
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem xs={7}>
          {fleetMapLoading ? (
            <Loader />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: `${fleetMap?.map_html}`,
              }}
            />
          )}
        </GridItem>
      </GridContainer>
    </div>
  )
}

FleetMap.propTypes = {
  classes: PropTypes.object,
  fleetMap: PropTypes.object,
  fleetMapLoading: PropTypes.bool,
  getFleetMap: PropTypes.func,
  fleet: PropTypes.object,
  fleetLoading: PropTypes.bool,
  getFleet: PropTypes.func,
  getCustomerList: PropTypes.func,
  customerList: PropTypes.array,
  customerListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleet,
      getFleetMap,
      getCustomerList,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      fleetMap: store.fleet.fleetMap,
      fleetMapLoading: store.fleet.fleetMapLoading,
      fleet: store.fleet.fleet,
      fleetLoading: store.fleet.fleetLoading,
      customerList: store.customer.customerList,
      customerListLoading: store.customer.customerListLoading,
    }
  }, mapDispatchToProps)
)(FleetMap)
