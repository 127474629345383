import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import cx from 'classnames'

const CustomTableRow = (props) => {
  const {classes, children, className, ...rest} = props

  return (
    <TableRow {...rest} className={cx(classes.tableRow, className)}>
      {children}
    </TableRow>
  )
}

CustomTableRow.propTypes = {
  classes: PropTypes.object,
  rest: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.object,
}
export default withStyles(materialStyle)(CustomTableRow)
