import React from 'react'
import {ENVIRONMENT, GATEWAY_URL} from 'helper/constants'
import browserHistory from 'helper/history'
import {
  LS_LOGGED_USER,
  LS_SESSION_ID_NAME,
  MOCK_API,
  MOCK_PORT_MAPPING,
} from 'helper/configConstants'
import {toast} from 'react-toastify'
import Toast from 'component/toast/Toast'
import {Trans} from '@lingui/macro'

/**
 * Get environment parameter from .env file
 * @param {string} envName - name of parameter
 */
export const resolveEnv = (envName) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName]
  }
  return process.env[envName]
}

/**
 * Redirect user to specific url, can be used outside of component
 * @param {string} route - url to be redirected to
 */
export const redirectTo = (route) => {
  browserHistory.push(route)
}

/**
 * Add service name to gateway url or use mock if set in configConstants
 * @param {string} name - name of service
 */
export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const hasUserSessionId = () => {
  return !!getItemFromStorage(LS_SESSION_ID_NAME)
}

export const isLoggedUser = () => {
  return !!getItemFromStorage(LS_LOGGED_USER)
}

export const isAdminUser = () => {
  const user = getItemFromStorage(LS_LOGGED_USER)
  return user?.user?.roles?.some((role) => role.name === 'admin')
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options = {autoClose: 15000}) => {
  return toast.error(<Toast message={message} type="error" />, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

const loopThroughErrors = (message, errors) => {
  if (errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(<>{err?.message || message}</>)
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
      }
    })
  } else {
    fireErrorToast(message)
  }
}

/**
 * Function used for basic error handling in catch(err=>globalApiErrorHandler(err))
 * @param error
 */
export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data} = error.response

    switch (status) {
      case 400: // bad request
        data?.errors && loopThroughErrors(<Trans>Bad request</Trans>, data.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 401: // unauthorized
        fireErrorToast(<Trans>Unauthorized</Trans>)
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
        break
      case 403: // forbidden
        data?.errors && loopThroughErrors(<Trans>Forbidden</Trans>, data.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 404: // not found
        data?.errors && loopThroughErrors(<Trans>Not found</Trans>, data.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 422: // unprocessable entity
        data?.errors && loopThroughErrors(<Trans>Unprocessable entity</Trans>, data.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 500: // internal server error
        data?.errors && loopThroughErrors(<Trans>Internal server error</Trans>, data.errors)
        return Promise.reject(error?.response?.data?.errors)
      default:
        data?.errors
          ? loopThroughErrors(<Trans>Bad request</Trans>, data.errors)
          : fireErrorToast(<Trans>Unknown server error</Trans>)
        return Promise.reject(error?.response?.data?.errors)
    }
  } else if (error.message === 'Network Error' || error.message === 'Server connection issue') {
    fireErrorToast(<Trans>Server connection issue</Trans>)
  } else {
    fireErrorToast(<Trans>Unknown error</Trans>)
  }
  return false
}

export const mapErrorResponseToForm = (err) => {
  let parsedError = {}
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e.source.includes('.')) {
        let errorSplit = e.source.split('.')
        if (parsedError.hasOwnProperty(errorSplit[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        } else {
          parsedError[errorSplit[0]] = {}
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        }
      } else {
        parsedError[e.source] = e.message
      }
    })
  }
  return parsedError
}

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const getItemFromStorage = (key, defaultValue = undefined) => {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue
  } catch (e) {
    console.error(e)
    fireErrorToast(<Trans>Local storage could not be parsed</Trans>)
    return defaultValue
  }
}

export const setItemToStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))
}
