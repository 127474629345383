import {resolveEnv} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const ENVIRONMENT = process.env.NODE_ENV
export const PUBLIC_API_KEY = resolveEnv('REACT_APP_PUBLIC_API_KEY')
export const GATEWAY_URL = resolveEnv('REACT_APP_GATEWAY_URL')

export const EDI_RESET_PSW_URL = resolveEnv('REACT_APP_EDI_RESET_PASSWORD_URL')
export const INVOICES_RESET_PSW_URL = resolveEnv('REACT_APP_INVOICES_RESET_PASSWORD_URL')

// database date format
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_DISPLAY_FORMAT = 'DD.MM.YYYY HH:mm'

export const CZ_DPH = [0, 21]
export const SK_DPH = [0, 10, 20]

export const EXPORT_TYPE = [
  {
    label: <Trans>Incoming</Trans>,
    value: 'INCOMING',
  },
  {
    label: <Trans>Outgoing</Trans>,
    value: 'OUTGOING',
  },
]
