import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {
  createAttachmentType,
  getAttachmentType,
  patchAttachmentType,
} from 'redux/action/attachmentTypesAction'
import {useParams} from 'react-router-dom'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import CheckBoxInput from 'component/field/CheckBoxInput'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'

const AttachmentTypeDetail = (props) => {
  const {
    classes,
    patchAttachmentType,
    getAttachmentType,
    attachment,
    attachmentLoading,
    createAttachmentType,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      enabled: !!values.enabled,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createAttachmentType(preparedValues)
        .then((res) => {
          redirectTo('/attachment')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchAttachmentType(id, preparedValues)
        .then((res) => {
          redirectTo('/attachment')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...attachment,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getAttachmentType(id)
      }
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="name"
                      label={<Trans>Name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="code"
                      label={<Trans>Code</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

AttachmentTypeDetail.propTypes = {
  classes: PropTypes.object,
  patchAttachmentType: PropTypes.func,
  getAttachmentType: PropTypes.func,
  createAttachmentType: PropTypes.func,
  attachment: PropTypes.object,
  attachmentLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAttachmentType,
      patchAttachmentType,
      createAttachmentType,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      attachment: store.attachment.attachmentType,
      attachmentLoading: store.attachment.attachmentTypeLoading,
    }
  }, mapDispatchToProps)
)(AttachmentTypeDetail)
