import {
  GET_MARKS_FAIL,
  GET_MARKS_REQUEST,
  GET_MARKS_SUCCESS,
  GET_SUP_AVAILA_FAIL,
  GET_SUP_AVAILA_REQUEST,
  GET_SUP_AVAILA_SUCCESS,
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_LIST_FAIL,
  GET_SUPPLIER_LIST_REQUEST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_REQUEST,
  GET_SUPPLIER_SUCCESS,
} from 'redux/actionType'

const initState = {
  supplierList: [],
  supplierListLoading: false,
  supplierListMeta: {},
  supplier: {},
  supplierLoading: false,
  marks: [],
  marksLoading: false,
  marksMeta: {},
  supplierUnavaLoading: false,
  supplierUnava: [],
  supplierUnavaMeta: {},
}

const supplierReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_SUPPLIER_LIST_REQUEST: {
      return {
        ...state,
        supplierListLoading: true,
      }
    }
    case GET_SUPPLIER_LIST_SUCCESS: {
      return {
        ...state,
        supplierListLoading: false,
        supplierList: action.data?.objects,
        supplierListMeta: action.data?.meta,
      }
    }
    case GET_SUPPLIER_LIST_FAIL: {
      return {
        ...state,
        supplierListLoading: false,
      }
    }
    case GET_SUPPLIER_REQUEST: {
      return {
        ...state,
        supplierLoading: true,
      }
    }
    case GET_SUPPLIER_SUCCESS: {
      return {
        ...state,
        supplier: action.data,
        supplierLoading: false,
      }
    }
    case GET_SUPPLIER_FAIL: {
      return {
        ...state,
        supplierLoading: false,
      }
    }
    case GET_MARKS_REQUEST: {
      return {
        ...state,
        marksLoading: true,
      }
    }
    case GET_MARKS_SUCCESS: {
      return {
        ...state,
        marksLoading: false,
        marks: action.data?.objects,
        marksMeta: action.data?.meta,
      }
    }
    case GET_MARKS_FAIL: {
      return {
        ...state,
        marksLoading: false,
      }
    }
    case GET_SUP_AVAILA_REQUEST: {
      return {
        ...state,
        supplierUnavaLoading: true,
      }
    }
    case GET_SUP_AVAILA_SUCCESS: {
      return {
        ...state,
        supplierUnavaLoading: false,
        supplierUnava: action.data?.objects,
        supplierUnavaMeta: action.data?.meta,
      }
    }
    case GET_SUP_AVAILA_FAIL: {
      return {
        ...state,
        supplierUnavaLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default supplierReducer
