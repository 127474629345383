import React from 'react'
import {Router, Switch, Redirect} from 'react-router-dom'
import browserHistory from 'helper/history'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import LoginPage from 'page/LoginPage'
import {I18nProvider} from '@lingui/react'
import {i18n} from '@lingui/core'
import catalogEn from 'App/locale/en/messages'
import catalogCs from 'App/locale/cs/messages'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import toastStyle from 'component/toast/toastStyle'
import moment from 'moment'
import 'moment/min/locales'
import PrivateRoute from 'layout/PrivateLayout/PrivateRoute'
import PublicRoute from 'layout/PublicLayout/PublicRoute'
import ResetPasswordPage from 'page/ResetPasswordPage'
import CustomersPage from 'page/CustomersPage'
import CFSPage from 'page/CFSPage'
import FleetsPage from 'page/FleetsPage'
import TermsPage from 'page/TermsPage'
import PredefinedTermsPage from 'page/PredefinedTermsPage'
import TypeOfWorksPage from 'page/TypeOfWorksPage'
import AreaOfWorksPage from 'page/AreaOfWorksPage'
import UnitsPage from 'page/UnitsPage'
import RegionsPage from 'page/RegionsPage'
import SuppliersPage from 'page/SuppliersPage'
import CarsPage from 'page/CarsPage'
import AttachmentTypesPage from 'page/AttachmentTypesPage'
import CompaniesPage from 'page/CompaniesPage'
import StatusesPage from 'page/StatusesPage'
import CustomerDetail from 'component/CustomersPage/CustomerDetail'
import FleetDetail from 'component/FleetsPage/FleetDetail'
import TermDetail from 'component/TermsPage/TermDetail'
import PredefinedTermDetail from 'component/PredefinedTermsPage/PredefinedTermDetail'
import TypeOfWorkDetail from 'component/TypeOfWorksPage/TypeOfWorkDetail'
import AreaOfWorkDetail from 'component/AreaOfWorksPage/AreaOfWorkDetail'
import UnitDetail from 'component/UnitsPage/UnitDetail'
import RegionDetail from 'component/RegionsPage/RegionDetail'
import SupplierDetail from 'component/SuppliersPage/SupplierDetail'
import CarDetail from 'component/CarsPage/CarDetail'
import AttachmentTypeDetail from 'component/AttachmentTypesPage/AttachmentTypeDetail'
import CompanyDetail from 'component/CompaniesPage/CompanyDetail'
import StatusDetail from 'component/StatusesPage/StatusDetail'
import CFSDetail from 'component/CFSPage/CFSDetail'
import FleetMap from 'component/FleetsPage/FleetMap'
import UsersPage from 'page/UsersPage'
import UserDetail from 'component/UsersPage/UserDetail'
import ExportBulkInvoicesByCustomerPage from 'page/ExportBulkInvoicesByCustomerPage'
import ExportInvoiceByType from 'page/ExportInvoiceByType'

function App(props) {
  const {classes} = props

  moment.locale('en')

  i18n.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18n.activate('en')

  return (
    <I18nProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistory}>
        <Switch>
          {/* PUBLIC ROUTES */}
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute exact path="/reset-password/:token" component={ResetPasswordPage} />

          {/* PRIVATE ROUTES */}
          <PrivateRoute exact path="/cfs" component={CFSPage} />
          <PrivateRoute exact path="/cfs/:id" component={CFSDetail} />

          <PrivateRoute exact path="/customer" component={CustomersPage} />
          <PrivateRoute exact path="/customer/:id" component={CustomerDetail} />

          <PrivateRoute exact path="/fleet" component={FleetsPage} />
          <PrivateRoute exact path="/fleet/:id" component={FleetDetail} />
          <PrivateRoute exact path="/fleet/:id/map" component={FleetMap} />

          <PrivateRoute exact path="/term" component={TermsPage} />
          <PrivateRoute exact path="/term/:id" component={TermDetail} />

          <PrivateRoute exact path="/predefined-term" component={PredefinedTermsPage} />
          <PrivateRoute exact path="/predefined-term/:id" component={PredefinedTermDetail} />

          <PrivateRoute exact path="/type-of-work" component={TypeOfWorksPage} />
          <PrivateRoute exact path="/type-of-work/:id" component={TypeOfWorkDetail} />

          <PrivateRoute exact path="/area-of-work" component={AreaOfWorksPage} />
          <PrivateRoute exact path="/area-of-work/:id" component={AreaOfWorkDetail} />

          <PrivateRoute exact path="/unit" component={UnitsPage} />
          <PrivateRoute exact path="/unit/:id" component={UnitDetail} />

          <PrivateRoute exact path="/region" component={RegionsPage} />
          <PrivateRoute exact path="/region/:id" component={RegionDetail} />

          <PrivateRoute exact path="/supplier" component={SuppliersPage} />
          <PrivateRoute exact path="/supplier/:id" component={SupplierDetail} />

          <PrivateRoute exact path="/car" component={CarsPage} />
          <PrivateRoute exact path="/car/:id" component={CarDetail} />

          <PrivateRoute exact path="/attachment" component={AttachmentTypesPage} />
          <PrivateRoute exact path="/attachment/:id" component={AttachmentTypeDetail} />

          <PrivateRoute exact path="/company" component={CompaniesPage} />
          <PrivateRoute exact path="/company/:id" component={CompanyDetail} />

          <PrivateRoute exact path="/status" component={StatusesPage} />
          <PrivateRoute exact path="/status/:id" component={StatusDetail} />

          <PrivateRoute exact path="/user" component={UsersPage} />
          <PrivateRoute exact path="/user/:id" component={UserDetail} />

          <PrivateRoute
            exact
            path="/export-bulk-invoices-by-customer"
            component={ExportBulkInvoicesByCustomerPage}
          />
          <PrivateRoute exact path="/export-incoming" component={ExportInvoiceByType} />
          <PrivateRoute exact path="/export-outgoing" component={ExportInvoiceByType} />

          <PrivateRoute path="/" component={CFSPage} />

          <Redirect to="/login" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

App.propTypes = {
  globalSettings: PropTypes.object,
  classes: PropTypes.object,
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      globalSettings: store.globalSettings,
    }
  })
)(App)
