import {
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_LIST_REQUEST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getCustomerList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.customers + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getCustomerListBulkExportable = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_LIST_REQUEST})
    return httpClient
      .get(endpoints.customersHasBulkExportableInvoices, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CUSTOMER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getCustomer = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CUSTOMER_REQUEST})
    return httpClient
      .get(endpoints.customers + '/' + id)
      .then((res) => {
        dispatch({type: GET_CUSTOMER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CUSTOMER_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createCustomer = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.customers, data)
      .then((res) => {
        fireSuccessToast(<Trans>New customer created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteCustomer = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.customers + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Customer deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchCustomer = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.customers + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Customer updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
