import React, {useEffect, useState} from 'react'
import {isLoggedUser, redirectTo} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Table from 'component/material/table/Table'
import {getCarList} from 'redux/action/carsAction'
import PrimaryButton from 'component/material/PrimaryButton'
import GridItem from 'component/material/GridItem'
import {Field, Form} from 'react-final-form'
import TextInput from 'component/field/TextInput'
import GridContainer from 'component/material/GridContainer'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {getFleetList} from 'redux/action/fleetsAction'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import CustomTooltip from 'component/material/CustomTooltip'
import {validateVin} from 'helper/validations'
import httpClient from 'helper/httpClient'
import AsyncInput from 'component/field/AsyncInput'
import endpoints from 'helper/endpoints'

let intervalId = null
let searchInterval = null

const CarsPage = (props) => {
  const {classes, rowsPerPage, getCarList, carList, carListLoading, carListMeta, fleetListLoading} =
    props

  const [tableSort, setTableSort] = useState({orderBy: 'rz', orderDirection: 'ASC'})
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({})
  const [filtersTemp, setFiltersTemp] = useStateWithCallbackLazy({})
  const [searchValue, setSearchValue] = useState(null)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleTableSort = (sort) => {
    setTableSort(sort)
  }

  const openDetail = (row) => (e) => {
    redirectTo('/car/' + row.id)
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      label: <Trans>ID</Trans>,
      width: '60px',
    },
    {
      name: 'rz',
      sortKey: 'rz',
      label: <Trans>RZ</Trans>,
    },
    {
      name: 'vin',
      sortKey: 'vin',
      label: <Trans>VIN</Trans>,
    },
    {
      name: 'enabled',
      sortKey: 'enabled',
      label: <Trans>Enabled</Trans>,
      render: (val) => (val ? '' : <Trans>disabled</Trans>),
    },
    {
      name: 'customer_cfs',
      sortKey: 'customer_cfs',
      label: <Trans>Fleet</Trans>,
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/car/new')
  }

  const onFilterChange = (e) => {
    const value = e.target?.value
    const name = e.target?.name
    setFiltersTemp(
      (prevState) => ({...prevState, [name]: value}),
      (value) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
          setPage(0)
          setFilters(value)
        }, 500)
      }
    )
  }

  const handleSubmit = () => {
    // empty submit method, there is no need for form submit, filtering is on change
    // but still it is easier to implement fields with in the form
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      setPage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    if (isLoggedUser()) {
      getCarList(rowsPerPage, page * rowsPerPage, tableSort, filters, searchValue)
    }
    return () => {
      clearTimeout(intervalId)
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, filters, searchValue])

  const onAsyncPaginationChange = (name, value) => {
    setFiltersTemp(
      (prevState) => ({...prevState, [name]: value}),
      (value) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
          setPage(0)
          setFilters(value)
        }, 500)
      }
    )
  }

  const loadFleetOptions = async (search, loadedOptions) => {
    let data = [...loadedOptions]
    let hasMore = false
    const searchQuery = search ? `?search=${search}` : ''
    await httpClient
      .get(endpoints.fleets + searchQuery, {
        limit: 50,
        offset: loadedOptions.length,
        order_by: 'customer:ASC',
      })
      .then((res) => {
        data.push(
          ...res?.data?.objects.map((r) => ({
            value: r.id,
            label: `${r.customer_code ? r.customer_code : ''} ${
              r.customer_cfs ? '(' + r.customer_cfs + ')' : ''
            }`,
          }))
        )
        hasMore = res?.data?.meta?.total_count > loadedOptions.length
      })

    return {
      options: data,
      hasMore: hasMore,
    }
  }

  return (
    <>
      <Accordion className={classes.tableFilters} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <BoxFullWidth className={classes.formTitle}>
            <Trans>Filters</Trans>
          </BoxFullWidth>
        </AccordionSummary>
        <AccordionDetails>
          <Form onSubmit={handleSubmit}>
            {(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <GridContainer
                    spacing={4}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <GridItem container xs={12} sm={6} md={4} lg={3}>
                      <Field
                        disabled={formProps.submitting}
                        name="rz"
                        label={<Trans>RZ</Trans>}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6} md={4} lg={3}>
                      <Field
                        disabled={formProps.submitting}
                        name="vin"
                        label={<Trans>VIN</Trans>}
                        component={TextInput}
                        onChange={onFilterChange}
                        showHelperText={false}
                        filters={true}
                        validate={validateVin}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6} md={4} lg={3}>
                      <AsyncInput
                        name="customer_cfs"
                        label={<Trans>Customer cfs</Trans>}
                        isSearchable={true}
                        loadOptions={loadFleetOptions}
                        onChange={onAsyncPaginationChange}
                        disabled={formProps.submitting}
                        loading={fleetListLoading}
                        showHelperText={false}
                        filters={true}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              )
            }}
          </Form>
        </AccordionDetails>
      </Accordion>
      <BoxFullWidth>
        <div className={classes.listPageControlContainer}>
          <div className={classes.searchContainer}>
            <div className={classes.searchLabel}>
              <Trans>Search:</Trans>
            </div>
            <TextInput
              onChange={handleSearchChange}
              loading={carListLoading}
              className={classes.searchInput}
              showHelperText={false}
            />
          </div>
          <div>
            <CustomTooltip title={<Trans>New car</Trans>}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                text={<Trans>Add</Trans>}
                onClick={openNew}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className={classes.overFlowAuto}>
          <Table
            data={carList}
            columns={columns}
            loading={carListLoading}
            onTableColumnSort={handleTableSort}
            tableSort={tableSort}
            count={carListMeta.total_count}
            page={page}
            meta={carListMeta}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>
    </>
  )
}

CarsPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getCarList: PropTypes.func,
  carList: PropTypes.array,
  carListLoading: PropTypes.bool,
  carListMeta: PropTypes.object,
  getFleetList: PropTypes.func,
  fleetList: PropTypes.array,
  fleetListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCarList,
      getFleetList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      carList: store.car.carList,
      carListLoading: store.car.carListLoading,
      carListMeta: store.car.carListMeta,
      fleetList: store.fleet.fleetList,
      fleetListLoading: store.fleet.fleetListLoading,
    }
  }, mapDispatchToProps)
)(CarsPage)
