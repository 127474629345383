import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import cx from 'classnames'

const CheckBoxInput = (props) => {
  const {
    input,
    meta,
    label,
    labelPlacement = 'end',
    helperText,
    showHelperText = true,
    labelClassName,
    classes,
    onChange,
    filters,
    inLineInput,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && meta.error
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <FormControl
      error={!!errorMessage}
      className={cx(
        classes.checkBoxControl,
        filters && classes.filtersCheckBoxControl,
        inLineInput && classes.flexGrowRow
      )}
      onChange={(e) => {
        input?.onChange(e)
        onChange && onChange(e)
      }}
    >
      <div
        className={cx(
          classes.fieldLabel,
          filters && classes.filtersFieldLabel,
          inLineInput && classes.inLineFieldLabel
        )}
      >
        {label}
      </div>
      <Checkbox {...input} {...rest} color="primary" />
      <FormHelperText>{errorHelperText}</FormHelperText>
    </FormControl>
  )
}

CheckBoxInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelPlacement: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showHelperText: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(fieldStyle)(CheckBoxInput)
