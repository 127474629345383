import {
  GET_MARKS_FAIL,
  GET_MARKS_REQUEST,
  GET_MARKS_SUCCESS,
  GET_SUP_AVAILA_FAIL,
  GET_SUP_AVAILA_REQUEST,
  GET_SUP_AVAILA_SUCCESS,
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_LIST_FAIL,
  GET_SUPPLIER_LIST_REQUEST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_REQUEST,
  GET_SUPPLIER_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getSupplierList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_SUPPLIER_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.suppliers + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_SUPPLIER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUPPLIER_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getSupplier = (id) => {
  return (dispatch) => {
    dispatch({type: GET_SUPPLIER_REQUEST})
    return httpClient
      .get(endpoints.suppliers + '/' + id)
      .then((res) => {
        dispatch({type: GET_SUPPLIER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUPPLIER_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createSupplier = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.suppliers, data)
      .then((res) => {
        fireSuccessToast(<Trans>New supplier created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteSupplier = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.suppliers + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Supplier deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchSupplier = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.suppliers + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Supplier updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getMarks = () => {
  return (dispatch) => {
    dispatch({type: GET_MARKS_REQUEST})
    return httpClient
      .get(endpoints.marks, {
        order_by: 'name:ASC',
      })
      .then((res) => {
        dispatch({type: GET_MARKS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MARKS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getSupUnavailabilities = (id) => {
  return (dispatch) => {
    dispatch({type: GET_SUP_AVAILA_REQUEST})
    return httpClient
      .get(endpoints.suppliers + '/' + id + '/unavailabilities', {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_SUP_AVAILA_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUP_AVAILA_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const putSupUnavailabilities = (id, data) => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.suppliers + '/' + id + '/unavailabilities', data)
      .then((res) => {
        fireSuccessToast(<Trans>Unavailability dates updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
