import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import {useParams} from 'react-router-dom'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {createTerm, getTerm, patchTerm} from 'redux/action/termsAction'
import {getFleetList} from 'redux/action/fleetsAction'
import {getRegionList} from 'redux/action/regionsAction'
import {getUnitList} from 'redux/action/unitsAction'
import SelectInput from 'component/field/SelectInput'
import {getTypeOfWorkList} from 'redux/action/typesOfWorkAction'
import TextInput from 'component/field/TextInput'
import CheckBoxInput from 'component/field/CheckBoxInput'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'

const TermDetail = (props) => {
  const {
    classes,
    term,
    termLoading,
    createTerm,
    patchTerm,
    getTerm,
    getFleetList,
    getRegionList,
    getUnitList,
    getTypeOfWorkList,
    fleetList,
    fleetListLoading,
    regionList,
    regionListLoading,
    unitList,
    unitListLoading,
    typeOfWorkList,
    typeOfWorkListLoading,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      insured_event: !!values.insured_event,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createTerm(preparedValues)
        .then((res) => {
          redirectTo('/term')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchTerm(id, preparedValues)
        .then((res) => {
          redirectTo('/term')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...term,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getTerm(id)
      }
      getFleetList()
      getRegionList()
      getUnitList()
      getTypeOfWorkList(1000, 0, {orderBy: 'description', orderDirection: 'ASC'})
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {region_id: 1}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="fleet_id"
                      label={<Trans>Fleet</Trans>}
                      component={SelectInput}
                      options={fleetList?.map((fleet) => ({
                        name: fleet.customer_code ? fleet.customer_code : fleet.customer,
                        value: fleet.id,
                      }))}
                      loading={fleetListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="type_of_work_id"
                      label={<Trans>Type of work</Trans>}
                      component={SelectInput}
                      options={typeOfWorkList?.map((type) => ({
                        name: type.description,
                        value: type.id,
                      }))}
                      loading={typeOfWorkListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="price"
                      label={<Trans>Price</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="unit_id"
                      label={<Trans>Unit</Trans>}
                      component={SelectInput}
                      options={unitList?.map((unit) => ({name: unit.description, value: unit.id}))}
                      loading={unitListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="region_id"
                      label={<Trans>Region</Trans>}
                      component={SelectInput}
                      options={regionList?.map((region) => ({name: region.code, value: region.id}))}
                      loading={regionListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="insured_event"
                      label={<Trans>Insured event</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

TermDetail.propTypes = {
  classes: PropTypes.object,
  term: PropTypes.object,
  termLoading: PropTypes.bool,
  createTerm: PropTypes.func,
  patchTerm: PropTypes.func,
  getTerm: PropTypes.func,
  fleetList: PropTypes.array,
  fleetListLoading: PropTypes.bool,
  regionList: PropTypes.array,
  regionListLoading: PropTypes.bool,
  unitList: PropTypes.array,
  unitListLoading: PropTypes.bool,
  typeOfWorkList: PropTypes.array,
  typeOfWorkListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createTerm,
      patchTerm,
      getTerm,
      getFleetList,
      getRegionList,
      getUnitList,
      getTypeOfWorkList,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      term: store.term.term,
      termLoading: store.term.termLoading,
      fleetList: store.fleet.fleetList,
      fleetListLoading: store.fleet.fleetListLoading,
      regionList: store.region.regionList,
      regionListLoading: store.region.regionListLoading,
      unitList: store.unit.unitList,
      unitListLoading: store.unit.unitListLoading,
      typeOfWorkList: store.typeOfWork.typeOfWorkList,
      typeOfWorkListLoading: store.typeOfWork.typeOfWorkListLoading,
    }
  }, mapDispatchToProps)
)(TermDetail)
