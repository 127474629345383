import {
  GET_TERM_FAIL,
  GET_TERM_LIST_FAIL,
  GET_TERM_LIST_REQUEST,
  GET_TERM_LIST_SUCCESS,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getTermList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_TERM_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.terms + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_TERM_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_TERM_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getTerm = (id) => {
  return (dispatch) => {
    dispatch({type: GET_TERM_REQUEST})
    return httpClient
      .get(endpoints.terms + '/' + id)
      .then((res) => {
        dispatch({type: GET_TERM_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_TERM_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createTerm = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.terms, data)
      .then((res) => {
        fireSuccessToast(<Trans>New term created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteTerm = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.terms + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Term deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchTerm = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.terms + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Term updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
