import {GET_ACCOUNTS_REQUEST, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_FAIL} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'

export const getAccountList = (limit = 1000, offset = 0, meta) => {
  return (dispatch) => {
    dispatch({type: GET_ACCOUNTS_REQUEST})
    return httpClient
      .get(endpoints.accounts, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_ACCOUNTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ACCOUNTS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
