import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import publicLayoutStyle from 'layout/PublicLayout/publicLayoutStyle'
import PropTypes from 'prop-types'
import LoginCarImg from './loginCar.png'

const PublicLayout = (props) => {
  const {classes, children} = props

  return (
    <div className={classes.container}>
      <img src={LoginCarImg} alt={'background-img'} />
      {children}
    </div>
  )
}

PublicLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default withStyles(publicLayoutStyle)(PublicLayout)
