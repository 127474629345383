import {
  GET_CAR_FAIL,
  GET_CAR_LIST_FAIL,
  GET_CAR_LIST_REQUEST,
  GET_CAR_LIST_SUCCESS,
  GET_CAR_REQUEST,
  GET_CAR_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getCarList = (limit = 1000, offset = 0, meta = {}, filters = {}, search) => {
  return (dispatch) => {
    dispatch({type: GET_CAR_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.cars + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        rz: filters?.rz ? filters.rz : null,
        vin: filters?.vin ? filters.vin : null,
        customer_id: filters?.customer_cfs ? filters.customer_cfs : null,
      })
      .then((res) => {
        dispatch({type: GET_CAR_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CAR_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getCar = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CAR_REQUEST})
    return httpClient
      .get(endpoints.cars + '/' + id)
      .then((res) => {
        dispatch({type: GET_CAR_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CAR_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createCar = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.cars, data)
      .then((res) => {
        fireSuccessToast(<Trans>New car created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteCar = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.cars + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Car deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchCar = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.cars + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Car updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
