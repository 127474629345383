import React, {useEffect} from 'react'
import DialogWindow from 'component/material/DialogWindow'
import {Trans} from '@lingui/macro'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import PropTypes from 'prop-types'
import {Field, Form} from 'react-final-form'
import {getFleetList} from 'redux/action/fleetsAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import SelectInput from 'component/field/SelectInput'
import CheckBoxInput from 'component/field/CheckBoxInput'
import {required} from 'helper/validations'

const EditFleetDialog = (props) => {
  const {open, row, onClose, handleAdd, getFleetList, fleetList, fleetListLoading} = props

  useEffect(() => {
    getFleetList(1000, 0)
  }, [])

  return (
    <DialogWindow
      title={<Trans>Update fleet</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth={true}
      dialogActions={
        <GridContainer spacing={3} justifyContent={'flex-end'}>
          <GridItem>
            <PrimaryButton
              onClick={onClose}
              variant={'outlined'}
              size={'small'}
              fullWidth={false}
              text={<Trans>Close</Trans>}
            />
          </GridItem>
          <GridItem>
            <PrimaryButton
              type="submit"
              size={'small'}
              onClick={() => document.getElementById('add-fleet-button').click()}
              fullWidth={false}
              text={<Trans>Update</Trans>}
            />
          </GridItem>
        </GridContainer>
      }
    >
      <Form
        onSubmit={handleAdd(row)}
        initialValues={
          row && {
            do_not_show_on_map_for_this_supplier: row?.do_not_show_on_map_for_this_supplier,
            fleet: row?.id,
          }
        }
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer spacing={4} direction={'row'}>
                <GridItem xs={12}>
                  <Field
                    name="fleet"
                    label={<Trans>Fleet</Trans>}
                    component={SelectInput}
                    options={fleetList.map((fleet) => ({
                      value: fleet.id,
                      name: fleet.customer_code,
                    }))}
                    disabled={true}
                    loading={fleetListLoading}
                    validate={required}
                    filters={true}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="do_not_show_on_map_for_this_supplier"
                    label={<Trans>Do not show on map</Trans>}
                    component={CheckBoxInput}
                    type={'checkbox'}
                    filters={true}
                  />
                </GridItem>
                {/* hidden button which is triggered from outside the form / component,
                  therefore it's hidden */}
                <button
                  disabled={formProps.submitting}
                  id={'add-fleet-button'}
                  type={'submit'}
                  style={{display: 'none'}}
                />
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </DialogWindow>
  )
}

EditFleetDialog.propTypes = {
  getFleetList: PropTypes.func,
  fleetList: PropTypes.array,
  fleetListMeta: PropTypes.object,
  fleetListLoading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleAdd: PropTypes.func,
  row: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFleetList,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      fleetList: store.fleet.fleetList,
      fleetListMeta: store.fleet.fleetListMeta,
      fleetListLoading: store.fleet.fleetListLoading,
    }
  }, mapDispatchToProps)
)(EditFleetDialog)
