import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const GridItem = (props) => {
  const {children, className, ...rest} = props
  return (
    <Grid item className={className} {...rest}>
      {children}
    </Grid>
  )
}

GridItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rest: PropTypes.object,
}

export default GridItem
