import {
  GET_STATUS_FAIL,
  GET_STATUS_LIST_FAIL,
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getStatusList = (limit = 1000, offset = 0, meta, search) => {
  return (dispatch) => {
    dispatch({type: GET_STATUS_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.statuses + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_STATUS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STATUS_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getStatus = (id) => {
  return (dispatch) => {
    dispatch({type: GET_STATUS_REQUEST})
    return httpClient
      .get(endpoints.statuses + '/' + id)
      .then((res) => {
        dispatch({type: GET_STATUS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STATUS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createStatus = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.statuses, data)
      .then((res) => {
        fireSuccessToast(<Trans>New status created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteStatus = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.statuses + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Status deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchStatus = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.statuses + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Status updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}
