import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'
import cx from 'classnames'
import Loader from 'component/material/Loader'

const PrimaryButton = (props) => {
  const {
    text,
    textPadding = 2,
    fullWidth = true,
    variant = 'contained',
    size = 'large',
    color = 'primary',
    formSubmit = true,
    classes,
    loading = false,
    className,
    ...rest
  } = props

  return (
    <Button
      className={cx(className, formSubmit ? classes.formSubmitButton : '')}
      color={color}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      {...rest}
    >
      {loading ? (
        <Loader className={classes.buttonLoader} />
      ) : (
        <Box sx={{p: textPadding, display: 'flex', alignItems: 'center'}}>{text}</Box>
      )}
    </Button>
  )
}

PrimaryButton.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.node,
  textPadding: PropTypes.number,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
}

export default withStyles(materialStyle)(PrimaryButton)
