import React, {useEffect} from 'react'
import {FORCE_NO_AUTH} from 'helper/configConstants'
import {hasUserSessionId, isLoggedUser, redirectTo} from 'helper/functions'
import {Route} from 'react-router-dom'
import PrivateLayout from 'layout/PrivateLayout/PrivateLayout'

const PrivateRoute = (props) => {
  const {component, ...rest} = props

  useEffect(() => {
    document.getElementsByTagName('html').scrollTop = 0

    if (!hasSessionId || !hasNoLoggedUser) {
      redirectTo('/login')
    }
  }, [])

  const hasSessionId = hasUserSessionId() || FORCE_NO_AUTH
  const hasNoLoggedUser = isLoggedUser() || FORCE_NO_AUTH

  // if user has no session id or has no logged used in local storage,
  // render nothing and await the redirect from condition above
  return !hasSessionId || !hasNoLoggedUser ? (
    <></>
  ) : (
    <PrivateLayout>
      <Route {...rest} component={component} />
    </PrivateLayout>
  )
}

export default PrivateRoute
