import {
  USER_PASSWORD_EDIT_REQUEST,
  USER_PASSWORD_EDIT_SUCCESS,
  USER_PASSWORD_EDIT_FAIL,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_LIST_FAIL,
} from 'redux/actionType'

const initState = {
  loading: false,
  userList: [],
  userListMeta: {},
  userListLoading: false,
  user: {},
  userLoading: false,
  roleList: [],
  roleListMeta: {},
  roleListLoading: false,
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST: {
      return {
        ...state,
        userListLoading: true,
      }
    }
    case GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        userListLoading: false,
        userList: action.data.objects,
        userListMeta: action.data.meta,
      }
    }
    case GET_USER_LIST_FAIL: {
      return {
        ...state,
        userListLoading: false,
      }
    }
    case GET_USER_REQUEST: {
      return {
        ...state,
        userLoading: true,
        user: {},
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: action.data,
        userLoading: false,
      }
    }
    case GET_USER_FAIL: {
      return {
        ...state,
        userLoading: false,
      }
    }
    case USER_PASSWORD_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case USER_PASSWORD_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case USER_PASSWORD_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_ROLE_LIST_REQUEST: {
      return {
        ...state,
        roleListLoading: true,
      }
    }
    case GET_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        roleListLoading: false,
        roleList: action.data.objects,
        roleListMeta: action.data.meta,
      }
    }
    case GET_ROLE_LIST_FAIL: {
      return {
        ...state,
        roleListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default userReducer
