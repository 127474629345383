import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  PUT_CHANGE_PASSWORD_REQUEST,
  PUT_CHANGE_PASSWORD_SUCCESS,
  PUT_CHANGE_PASSWORD_FAIL,
} from 'redux/actionType'

const initState = {
  loading: false,
  loginLoading: false,
  data: {},
  currentUser: null,
  currentUserLoading: false,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginLoading: true,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginLoading: false,
        data: action.data,
      }
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        loginLoading: false,
      }
    }
    case LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {},
      }
    }
    case LOGOUT_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_CURRENT_USER_REQUEST: {
      return {
        ...state,
        currentUserLoading: true,
      }
    }
    case GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        currentUserLoading: false,
        currentUser: action.data,
      }
    }
    case GET_CURRENT_USER_FAIL: {
      return {
        ...state,
        currentUserLoading: false,
      }
    }
    case PUT_CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePasswordLoading: true,
      }
    }
    case PUT_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: false,
      }
    }
    case PUT_CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        changePasswordLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default authReducer
