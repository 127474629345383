import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {createCompany, getCompany, patchCompany} from 'redux/action/companiesAction'
import {getCountryList} from 'redux/action/countriesAction'
import {getProviderList} from 'redux/action/providersAction'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import SelectInput from 'component/field/SelectInput'
import CheckBoxInput from 'component/field/CheckBoxInput'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'

const CompanyDetail = (props) => {
  const {
    classes,
    company,
    companyLoading,
    getCompany,
    patchCompany,
    getCountryList,
    getProviderList,
    providerList,
    providerListLoading,
    countryList,
    countryListLoading,
    createCompany,
  } = props

  let {id} = useParams()

  const prepareValues = (values) => {
    return {
      ...values,
      enabled: !!values.enabled,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createCompany(preparedValues)
        .then((res) => {
          redirectTo('/company')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchCompany(id, preparedValues)
        .then((res) => {
          redirectTo('/company')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...company,
    }
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getCompany(id)
      }
      getCountryList()
      getProviderList()
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="name"
                      label={<Trans>Name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="intercompany_name"
                      label={<Trans>Intercompany name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="dynamics_name"
                      label={<Trans>Dynamics name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="provider_id"
                      label={<Trans>Provider</Trans>}
                      component={SelectInput}
                      options={providerList}
                      loading={providerListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="country_id"
                      label={<Trans>Country</Trans>}
                      component={SelectInput}
                      options={countryList?.map((country) => ({
                        name: country.country_name,
                        value: country.id,
                      }))}
                      loading={countryListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

CompanyDetail.propTypes = {
  classes: PropTypes.object,
  patchCompany: PropTypes.func,
  getCompany: PropTypes.func,
  createCompany: PropTypes.func,
  getCountryList: PropTypes.func,
  getProviderList: PropTypes.func,
  company: PropTypes.object,
  companyLoading: PropTypes.bool,
  providerList: PropTypes.array,
  providerListLoading: PropTypes.bool,
  countryList: PropTypes.array,
  countryListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCompany,
      patchCompany,
      getCountryList,
      getProviderList,
      createCompany,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      company: store.company.company,
      companyLoading: store.company.companyLoading,
      providerList: store.provider.providerList,
      providerListLoading: store.provider.providerListLoading,
      countryList: store.country.countryList,
      countryListLoading: store.country.countryListLoading,
    }
  }, mapDispatchToProps)
)(CompanyDetail)
