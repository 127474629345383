import React, {useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import detailPageStyle from 'component/detailPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {createSupplier, getMarks, getSupplier, patchSupplier} from 'redux/action/suppliersAction'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import {useParams} from 'react-router-dom'
import {isLoggedUser, mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PrimaryButton from 'component/material/PrimaryButton'
import TextInput from 'component/field/TextInput'
import {getProviderList} from 'redux/action/providersAction'
import {getCountryList} from 'redux/action/countriesAction'
import SelectInput from 'component/field/SelectInput'
import {validateEmail} from 'helper/validations'
import CheckBoxInput from 'component/field/CheckBoxInput'
import Box from '@material-ui/core/Box'
import Table from 'component/material/table/Table'
import {ReactComponent as Bin} from 'style/asset/bin.svg'
import AddFleetDialog from 'component/SuppliersPage/AddFleetDialog'
import {Add} from '@material-ui/icons'
import cx from 'classnames'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import EditFleetDialog from 'component/SuppliersPage/EditFleetDialog'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DialogWindow from 'component/material/DialogWindow'
import AvailabilityCalendar from 'component/SuppliersPage/Calendar'

const SupplierDetail = (props) => {
  const {
    classes,
    getSupplier,
    supplier,
    supplierLoading,
    patchSupplier,
    getProviderList,
    getCountryList,
    providerList,
    providerListLoading,
    countryList,
    countryListLoading,
    createSupplier,
    getMarks,
    marksLoading,
    marks,
  } = props

  let {id} = useParams()

  const [supplierFleets, setSupplierFleets] = useState([])
  const [openFleetDialog, setOpenFleetDialog] = useState(false)
  const [openEditFleeDialog, setOpenEditFleeDialog] = useState({visible: false, row: null})

  const [openCalendar, setOpenCalendar] = useState(false)

  const handleOpenCalendar = () => {
    setOpenCalendar(true)
  }

  const handleCloseCalendar = () => {
    setOpenCalendar(false)
  }

  const prepareValues = (values) => {
    return {
      ...values,
      enabled: !!values.enabled,
      mark_id: values.mark_id ? values.mark_id : null,
      pref_level: values.pref_level ? Number(values.pref_level) : null,
      branch_office: !!values.branch_office,
      fleet_ids: supplierFleets.map((fleet) => ({
        id: fleet.fleet?.id,
        do_not_show_on_map_for_this_supplier: fleet.do_not_show_on_map_for_this_supplier,
      })),
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    if (id === 'new') {
      return createSupplier(preparedValues)
        .then((res) => {
          redirectTo('/supplier')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      return patchSupplier(id, preparedValues)
        .then((res) => {
          redirectTo('/supplier')
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    }
  }

  const getInitialValues = () => {
    return {
      ...supplier,
    }
  }

  const fleetColumns = [
    {
      name: 'id',
      label: <Trans>ID</Trans>,
    },
    {
      name: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: 'customer',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'customer_cfs',
      label: <Trans>CFS</Trans>,
    },
    {
      name: 'customer_ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'customer_dic',
      label: <Trans>DIC</Trans>,
    },
    {
      name: 'do_not_show_on_map_for_this_supplier',
      label: <Trans>Do not show on map</Trans>,
      render: (val) => (val ? <Trans>Yes</Trans> : <Trans>No</Trans>),
    },
    {
      name: 'id',
      label: '',
      align: 'right',
      width: 60,
      render: (val, row) => (
        <GridContainer justifyContent={'space-between'}>
          <GridItem>
            <div
              onClick={handleOpenEditDialog(row)}
              className={cx(classes.cursorPointer, classes.textCenter)}
            >
              <Edit />
            </div>
          </GridItem>
          <GridItem>
            <div
              onClick={handleRemoveFleet(row)}
              className={cx(classes.cursorPointer, classes.textCenter)}
            >
              <Bin />
            </div>
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const handleRemoveFleet = (row) => (e) => {
    setSupplierFleets((prevState) => prevState.filter((fleet) => fleet.fleet?.id !== row.id))
  }

  const handleAddFleet = (values) => {
    setSupplierFleets((prevState) => [
      ...prevState,
      {
        fleet: {
          ...values.fleet,
        },
        do_not_show_on_map_for_this_supplier: !!values.do_not_show_on_map_for_this_supplier,
      },
    ])
    handleCloseDialog()
  }

  const handleOpenDialog = () => {
    setOpenFleetDialog(true)
  }
  const handleCloseDialog = () => {
    setOpenFleetDialog(false)
  }

  const handleOpenEditDialog = (row) => () => {
    setOpenEditFleeDialog({visible: true, row: row})
  }
  const handleCloseEditDialog = () => {
    setOpenEditFleeDialog({visible: false, row: null})
  }

  const handleEditFleet = (row) => (value) => {
    const supplierFleet = supplierFleets.filter((fleet) => fleet?.fleet?.id !== value?.fleet)
    const editedSupplierFleet = {
      do_not_show_on_map_for_this_supplier: value.do_not_show_on_map_for_this_supplier,
      fleet: {
        id: row.id,
        customer_cfs: row.customer_cfs,
        customer: row.customer,
        customer_code: row.customer_code,
      },
    }

    setSupplierFleets(() => [...supplierFleet, editedSupplierFleet])
    handleCloseEditDialog()
  }

  const fieldLabel = {
    color: '#585b6c',
    fontSize: 12,
    fontWeight: 600,
    paddingBottom: 2,
    boxSizing: 'border-box',
    lineHeight: 1.1,
    minWidth: 180,
    textAlign: 'end',
    height: 30,
    alignSelf: 'flex-start',
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  }

  useEffect(() => {
    if (isLoggedUser()) {
      if (id !== 'new') {
        getSupplier(id).then((res) => {
          res.supplier_fleets && setSupplierFleets([...res.supplier_fleets])
        })
      }
      getProviderList(1000, 0, {orderBy: 'name', orderDirection: 'ASC'})
      getCountryList(1000, 0, {orderBy: 'country_name', orderDirection: 'ASC'})
      getMarks()
    } else {
      redirectTo('/')
    }
  }, [])

  return (
    <div className={classes.detailPageContainer}>
      <AddFleetDialog
        onClose={handleCloseDialog}
        open={openFleetDialog}
        handleAdd={handleAddFleet}
      />

      <EditFleetDialog
        onClose={handleCloseEditDialog}
        open={openEditFleeDialog.visible}
        row={openEditFleeDialog.row}
        handleAdd={handleEditFleet}
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={id !== 'new' ? getInitialValues() : {enabled: true}}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>General information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="name"
                      label={<Trans>Name</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="ic"
                      label={<Trans>IC</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="dic"
                      label={<Trans>DIC</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="customer_no"
                      label={<Trans>Customer no.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="edi_user_id"
                      label={<Trans>Edi user id</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="provider_id"
                      label={<Trans>Provider</Trans>}
                      component={SelectInput}
                      options={providerList}
                      loading={providerListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="country_id"
                      label={<Trans>Country</Trans>}
                      component={SelectInput}
                      options={countryList?.map((country) => ({
                        name: country.country_name,
                        value: country.id,
                      }))}
                      loading={countryListLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>Branch</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="branch"
                      label={<Trans>Branch</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="establishment"
                      label={<Trans>Establishment</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="account_no"
                      label={<Trans>Account no.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="supplier_no"
                      label={<Trans>Supplier no.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="ean"
                      label={<Trans>EAN</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="eos_id"
                      label={<Trans>EOS id</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="street"
                      label={<Trans>Street</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="building_no"
                      label={<Trans>Building no.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="city"
                      label={<Trans>City</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="postal_zone"
                      label={<Trans>Postal zone</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="location_x"
                      label={<Trans>Location X</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="location_y"
                      label={<Trans>Location Y</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="location_id"
                      label={<Trans>Location id</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="enabled"
                      label={<Trans>Enabled</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="branch_office"
                      label={<Trans>Branch office</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer item xs={1} />

                <GridContainer item xs={5}>
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>Contact information</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="address"
                      label={<Trans>Address</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="email"
                      label={<Trans>Email</Trans>}
                      component={TextInput}
                      validate={validateEmail}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="contact_person"
                      label={<Trans>Contact person</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="phone_1"
                      label={<Trans>Phone 1</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="phone_2"
                      label={<Trans>Phone 2</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="working_hours_mon_thu"
                      label={<Trans>Working hours Mon.-Thu.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="working_hours_fri"
                      label={<Trans>Working hours Fri.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="working_hours_sat"
                      label={<Trans>Working hours Sat.</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="business_lease"
                      label={<Trans>Business Lease</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="no_cars_per_day"
                      label={<Trans>Number of cars per day</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="no_spare_vehicles"
                      label={<Trans>Number of spare cars</Trans>}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="is_oe"
                      label={<Trans>Is OE supplier</Trans>}
                      component={CheckBoxInput}
                      type={'checkbox'}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting || !formProps.values.is_oe}
                      name="mark_id"
                      label={<Trans>Mark</Trans>}
                      component={SelectInput}
                      options={marks?.map((m) => ({
                        name: m.name,
                        value: m.id,
                      }))}
                      loading={marksLoading}
                      emptyOption={true}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="pref_level"
                      label={<Trans>Preference level</Trans>}
                      component={TextInput}
                      type={'number'}
                      inputJustifyRight={true}
                      inputProps={{min: 1, max: 3, step: 1}}
                    />
                  </GridItem>
                  <GridItem container xs={12}>
                    <Field
                      disabled={formProps.submitting}
                      name="note"
                      label={<Trans>Note</Trans>}
                      component={TextInput}
                      multiline
                      minRows={5}
                      inputJustifyRight={true}
                    />
                  </GridItem>

                  <GridItem container xs={12}>
                    <GridItem xs={false} style={fieldLabel}>
                      <Trans>Unavailability calendar</Trans>
                    </GridItem>

                    <GridItem xs={true}>
                      <DateRangeIcon
                        onClick={handleOpenCalendar}
                        className={classes.supplierCalendarIcon}
                      />
                      {/* CALENDAR DIALOG */}
                      <DialogWindow
                        title={<Trans>Setting unavailability of supplier</Trans>}
                        maxWidth={false}
                        fullWidth={false}
                        open={openCalendar}
                        onClose={handleCloseCalendar}
                      >
                        <AvailabilityCalendar onClose={handleCloseCalendar} id={id} />
                      </DialogWindow>
                    </GridItem>
                  </GridItem>
                </GridContainer>

                {/* FLEETS */}
                <GridItem container xs={12}>
                  <GridContainer direction="row" justifyContent="flex-start" alignItems="center">
                    <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                      <GridContainer justifyContent={'space-between'} alignItems={'center'}>
                        <GridItem xs={6}>
                          <Trans>Fleets</Trans>
                        </GridItem>
                        <GridItem container xs={6} justifyContent={'flex-end'}>
                          <PrimaryButton
                            fullWidth={false}
                            disabled={formProps.submitting}
                            size="small"
                            textPadding={5}
                            onClick={handleOpenDialog}
                            className={classes.addButton}
                            text={<Add fontSize={'small'} />}
                          />
                        </GridItem>
                      </GridContainer>
                    </BoxFullWidth>
                    <GridItem container xs={12}>
                      <Table
                        data={supplierFleets?.map((fleet) => ({
                          do_not_show_on_map_for_this_supplier:
                            fleet.do_not_show_on_map_for_this_supplier,
                          ...fleet.fleet,
                        }))}
                        loading={supplierLoading}
                        columns={fleetColumns}
                        pagination={false}
                        className={cx(classes.lastRowRoundBorder, classes.detailTable)}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridItem xs={12} container justifyContent="flex-end">
                <Box pt={5}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={formProps.submitting}
                    size="small"
                    type="submit"
                    textPadding={5}
                    text={<Trans>Save</Trans>}
                  />
                </Box>
              </GridItem>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

SupplierDetail.propTypes = {
  classes: PropTypes.object,
  getSupplier: PropTypes.func,
  patchSupplier: PropTypes.func,
  supplier: PropTypes.object,
  supplierLoading: PropTypes.bool,
  getProviderList: PropTypes.func,
  getCountryList: PropTypes.func,
  providerList: PropTypes.array,
  providerListLoading: PropTypes.bool,
  countryList: PropTypes.array,
  countryListLoading: PropTypes.bool,
  createSupplier: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSupplier,
      patchSupplier,
      createSupplier,
      getProviderList,
      getCountryList,
      getMarks,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      supplier: store.supplier.supplier,
      supplierLoading: store.supplier.supplierLoading,
      providerList: store.provider.providerList,
      providerListLoading: store.provider.providerListLoading,
      countryList: store.country.countryList,
      countryListLoading: store.country.countryListLoading,
      marksLoading: store.supplier.marksLoading,
      marks: store.supplier.marks,
    }
  }, mapDispatchToProps)
)(SupplierDetail)
