import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

const CustomTooltip = (props) => {
  const {children, title, classNames, placement = 'top', enterDelay = 300, ...rest} = props

  return (
    <Tooltip title={title} placement={placement} enterDelay={enterDelay} {...rest}>
      <div className={classNames}>{children}</div>
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  placement: PropTypes.string,
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CustomTooltip
